import { debounce } from 'lodash';
import { BoundingBox } from '../../../common/types';
import { store } from '../../../configuration/setup/store';
import { trace } from '../../../configuration/setup/trace';
import { selectIsSearchWidget } from '../../app/appSelectors';
import { selectIsTreeFilterActive } from '../../filteredStations/filteredStationsSelector';
import { updateBbox } from '../../filteredStations/filteredStationsSlice';
import { renderChargingPointsWithBbox } from '../actions/renderChargingPoints';

export const MAP_CHANGED_EVENT = 'EVENT_MAP_CHANGED';
const DEBOUNCE_TIMER = 0;

export type MapChangedType = {
    zoom: number;
    bbox: BoundingBox;
};

const mapChangedDebounceHandler = debounce((bbox: BoundingBox, isTreeFilterActive: boolean) => {
    if (!isTreeFilterActive) {
        renderChargingPointsWithBbox(bbox);
    }
}, DEBOUNCE_TIMER);

export const mapChangedHandler = (data: MapChangedType) => {
    const isSearchWidget = selectIsSearchWidget(store.getState());
    if (!isSearchWidget) {
        return;
    }
    trace.debug('Map changed event:', data);
    store.dispatch(updateBbox(data.bbox));

    const isTreeFilterActive = selectIsTreeFilterActive(store.getState());

    mapChangedDebounceHandler(data.bbox, isTreeFilterActive);
};
